// @use "../../../sassfiles/components/website/hero" as *;

.visionCard {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
  
  .teamCard {
    border-radius: 15px 15px 0px 0px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  }
  