* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 3.5rem;
  font-weight: 300;
  color: var(--white);
  font-family: "Pathway Gothic One", serif;
}

h2 {
  font-size: 4rem;
  font-weight: 400;
  font-family: "Pathway Gothic One", serif;
}

h3 {
  font-size: 2.2rem;
  font-family: "Pathway Gothic One", serif;
}

h4 {
  font-size: 1.3rem;
  font-family: "Rubik", serif;
}

h5 {
  font-size: 1.2rem;
  font-weight: 300;
  font-family: "Rubik", serif;
}

h6 {
  font-size: 0.82em;
  font-weight: 300;
  font-family: "Rubik", serif;
}

p {
  font-size: 1rem;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Poppins", serif;
  font-weight: 300;
  font-style: normal;
}

.footerParagraph {
  font-size: 1rem;
  font-weight: 300;
  color: #000 !important;
  line-height: 1.75rem;
  font-family: "Rubik", serif;
}

.WorkWithUsBtn {
  color: #0C000E;
  border: 1px solid #06bb06;
  width: 180px;
  height: 50px;
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-family: "Comic Neue", cursive;
  font-size: 1.2rem;
}
.WorkWithUsBtn:hover {
  background-color: #033F2D;
  color: #ffffff;
}

.RemoteBtn {
  color: #0C000E;
  background-color: rgba(1, 255, 2, 0.2); /* 0.6 is the opacity value */
  border: none;
  width: 130px;
  height: 28px;
  border-radius: 5px;
  font-family: "Comic Neue", cursive;
  font-size: 0.5rem;
}

.MainBtn {
  color: #0C000E;
  border: 1px solid #033F2D;
  width: 130px;
  height: 40px;
  border-radius: 5px;
  font-family: "Comic Neue", cursive;
  font-size: 0.5rem;
}
.MainBtn:hover {
  background-color: #033F2D;
  color: #ffffff;
}

.LandingPageHeroBgg::before {
  content: "";
  position: absolute;
  background-color: rgba(46, 49, 51, 0.4);
  height: 100vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.4;
}

.LandingPageHeroBgg {
  overflow-x: hidden;
}

.LandingPageHeroBg {
  background: url(/src/assets/home/homehero.jpg);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
}

.LandingPageHeroBg h1 {
  position: relative;
}

.LandingPageHeroBg h2 {
  position: relative;
}

.LandingPageHeroBg p {
  position: relative;
}

.LandingPageHeroBg,
.WorkWithUsBtn,
.WorkWithUsBtnn,
.JoinOurComunityBtn,
.ChallengeBtn {
  position: relative;
}

.gradientBg {
  background: rgba(0, 0, 0, 0.678);
}

.overlayBg {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 6;
  left: 0;
}

@media only screen and (max-width: 725px) {
  .LandingPageHeroBgg::before {
    content: "";
    height: 70vh;
  }
  .LandingPageHeroBg {
    height: 70vh;
  }
}
.processesNumber {
  font-size: 3.2rem;
  font-weight: 400;
  color: #0C000E;
}

.impactCard {
  border-radius: 100px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}

.impactNumber {
  font-size: 1.8rem;
}

.AboutPageHeroBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 100vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.2;
}

.AboutPageHeroBgg {
  overflow-x: hidden;
}

.AboutPageHeroBg {
  background: url(/src/assets/about/abouthero.jpg);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
}

.AboutPageHeroBg h1 {
  position: relative;
}

.AboutPageHeroBg h2 {
  position: relative;
}

.AboutPageHeroBg h3 {
  position: relative;
}

.AboutPageHeroBg p {
  position: relative;
}

.AboutPageHeroBg,
.WorkWithUsBtn,
.WorkWithUsBtnn,
.JoinOurComunityBtn {
  position: relative;
}

.JobsPrevHeroBgg::before {
  content: "";
  position: absolute;
  background-color: rgba(46, 49, 51, 0.4);
  height: 100vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.4;
}

.JobsPrevHeroBgg {
  overflow-x: hidden;
}

.JobsPrevHeroBg {
  background: url(/src/assets/jobs/jobshero.jpg);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
}

.JobsPrevHeroBg h1 {
  position: relative;
}

.JobsPrevHeroBg h2 {
  position: relative;
}

.JobsPrevHeroBg p {
  position: relative;
}

.JobsPrevHeroBg,
.WorkWithUsBtn,
.WorkWithUsBtnn,
.JoinOurComunityBtn,
.ChallengeBtn {
  position: relative;
}

.ServicesPageHeroBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 80vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.2;
}

.ServicesPageHeroBg {
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 80vh;
  width: 100%;
}

.ServicesPageHeroBg h1 {
  position: relative;
}

.ServicesPageHeroBg h2 {
  position: relative;
}

.ServicesPageHeroBg h3 {
  position: relative;
}

.ServicesPageHeroBg p {
  position: relative;
}

.ServicesPageHeroBg,
.WorkWithUsBtn,
.WorkWithUsBtnn,
.JoinOurComunityBtn {
  position: relative;
}

@media only screen and (max-width: 725px) {
  .AboutPageHeroBgg::before {
    content: "";
    height: 70vh;
  }
  .AboutPageHeroBg {
    height: 70vh;
  }
  .ServicesPageHeroBgg::before {
    content: "";
    height: 70vh;
  }
  .ServicesPageHeroBg {
    height: 70vh;
  }
}
@media only screen and (max-width: 991px) {
  .Navitemmobile {
    background: #fff;
    color: #000000;
    border: 1px solid #033F2D !important;
    border-radius: 4px;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    width: 14rem;
    height: 1.8rem;
  }
  .Navitemmobile:hover {
    background: #033F2D !important;
    color: #ffffff;
  }
  .Navitemmobile:focus {
    background: #033F2D !important;
    color: #ffffff;
  }
}
@media only screen and (max-width: 600px) {
  .Navicons {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 992px) {
  .NavUL {
    align-items: center;
    justify-content: center;
  }
}
.navText {
  font-weight: 400;
  font-family: "Rubik", serif;
}

@media (min-width: 992px) and (max-width: 1600px) {
  .Navitemlarge:hover {
    color: #033F2D !important;
  }
}
.WhatWeDoBg {
  background-color: #fcfcf9;
}

.WhatWeDoCard {
  border-radius: 16px;
  box-shadow: 0px 29px 20px 0px rgba(0, 0, 0, 0.04);
  border: 1px solid #06bb06;
}

.PartnersBg {
  background-color: #fdfbf9;
}

.visionCard {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
}

.teamCard {
  border-radius: 15px 15px 0px 0px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.JobsCard {
  border-radius: 16px;
  box-shadow: 0px 29px 20px 0px rgba(0, 0, 0, 0.04);
  border: 1px solid #033F2D;
}

.footerBg {
  background: #fff;
}

.LinkedIncolor, .IGcolor, .Twittercolor {
  color: #000000 !important;
}
/*# sourceMappingURL=styles.css.map */