// @use "./colors" as *;

h1 {
    font-size: 3.5rem;
    font-weight: 300;
    color: var(--white);
    font-family: "Pathway Gothic One", serif;
    // font-family: "Rubik", serif;
  }
  
  h2 {
    font-size: 4rem;
    font-weight: 400;
    font-family: "Pathway Gothic One", serif;
  }
  
  h3 {
    font-size: 2.2rem;
    // font-weight: 600;
    font-family: "Pathway Gothic One", serif;
    // color: #fbb03b;
  }
  h4 {
    font-size: 1.3rem;
    //   font-family: ;
    font-family: "Rubik", serif;
  }
  
  h5 {
    font-size: 1.2rem;
    font-weight: 300;
    font-family: "Rubik", serif;
  }
  h6 {
    font-size: 0.82em;
    font-weight: 300;
    font-family: "Rubik", serif;
  }
  
  p {
    font-size: 1rem;
    color: var(--white);
    line-height: 1.75rem;
    font-family: "Poppins", serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .footerParagraph {
    font-size: 1rem;
    font-weight: 300;
    color: #000 !important;
    line-height: 1.75rem;
    font-family:"Rubik", serif;
  }
  
  // .bigFont {
  //   font-size: 2.8rem;
  // }
  // .smallFont {
  //   font-size: 1.3rem;
  // }
  