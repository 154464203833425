@use "../colors" as *;
@use "../components/homeCards" as *;
// .LandingPageHeroBgg::before {
//   content: "";
//   position: absolute;
//   background-color: #2e3133;
//   height: 80vh;
//   width: 100%;
//   top: 0px;
//   right: 0px;
//   bottom: 0px;
//   left: 0px;
//   opacity: 0.4;
// }
// .LandingPageHeroBgg {
//   overflow-x: hidden;
// }

// .LandingPageHeroBg {
// //   background: url(/src/assets/website/images/hero/homehero/homehero.jpg);
//   background-size: cover;
//   position: relative;
//   top: 0px;
//   right: 0px;
//   bottom: 0px;
//   left: 0px;
//   height: 80vh;
//   width: 100%;
// }

// .LandingPageHeroBg h1 {
//   position: relative;
// }
// .LandingPageHeroBg h2 {
//   position: relative;
// }
// .LandingPageHeroBg p {
//   position: relative;
// }
// .LandingPageHeroBg,
// .WorkWithUsBtn,
// .WorkWithUsBtnn,
// .JoinOurComunityBtn,
// .ChallengeBtn {
//   position: relative;
// }

// @media only screen and (max-width: 725px) {
//   .LandingPageHeroBgg::before {
//     content: "";
//     height: 70vh;
//   }
//   .LandingPageHeroBg {
//     height: 70vh;
//   }
// }

.PartnersBg{
  background-color:#fdfbf9;
}

// .processesNumber {
//   font-size: 3.2rem;
//   font-weight: 400;
//   color: $prytextcolor;
//   // color: $secbtncolor;
// }
// .impactCard {
//   border-radius: 100px;
//   // background: $prybgcolor;
//   box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
// }
// .impactNumber {
//   font-size: 1.8rem;
// }


