@use "../colors" as *;

.WhatWeDoBg {
  // background-color: $secbgcolor;
  background-color:#fcfcf9;
}

.WhatWeDoCard {
  border-radius: 16px;
  box-shadow: 0px 29px 20px 0px rgba(0, 0, 0, 0.04);
  border: 1px solid $terbgcolor;
}
